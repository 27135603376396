/* fonts */
body,
.slider-content-left h1,
.filter-bar.page .content-title h1,
.detail-title-bar .content-title h1,
.breadcrumb h1,
.section.inhoud h1,
.section.inhoud h2,
.section.inhoud h3,
.content-title .breadcrumb,
.sold .verkocht,
.total,
.content-title,
.no,
.top-detail table th.table-title,
.top-detail table th,
h1,
h2,
h3,
h4,
h5,
h6,
span,
time,
input,
textarea,
a{
    font-family: Arial, sans-serif;
}
.row.top-detail .top-detail-right .alert.alert-warning{
    color: yellow;
}
.footer .container{
    background-color: $primary-background-color;
    color: white;
    a{
        color: white;
    }
}
@media (min-width: 1200px){
    .btn {
        font-size: 14px;
    }
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #ffffff;
            }
            .footer-funda svg{
                fill: #ffffff;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #ffffff;
            }
            .footer-nrvg svg{
                fill: #ffffff;
            }
        }
    }
}

.card__logo {
    display: none;
}
.detail-header-image-card__count .countdown-wrapper .card__logo {
    display: block;
}
