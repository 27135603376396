$primary-color: #778169;
$primary-background-color: #778169;
$primary-background-color-75: rgba(119, 129, 105, 0.75);
$primary-background-color-50: rgba(119, 129, 105,0.5);
$primary-background-color-25: rgba(119, 129, 105,0.25);
$primary-background-color-light: #e6eaed;
$primary-background-color-dark: #778169;

$secondary-color: #414142;
$secondary-background-color: #414142;
$secondary-background-color-75: rgba(65, 65, 65,  0.75);
$secondary-background-color-50: rgba(65, 65, 65, 0.5);
$secondary-background-color-25: rgba(65, 65, 65, 0.35);
$secondary-background-color-light: #e6eaed;


$red-color: #e63744;
$red-background-color: #e63744;

$text-color: #000000;
$title-color: darken($text-color, 5%);
$h1-color: #434343;

// Cookie
$mainCookieBG: #778169;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
